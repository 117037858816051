import React, { useState } from "react"
import Layout from "../layout"
import PageTitle from "../components/page-title"
import Panel from "../components/panel"
import PanelContent from "../components/panel/content"
import PanelTitle from "../components/panel/title"
import Seo from "../components/seo"
import base32 from "../lib/base32"
import meta from "../meta-constants"

function Base32Decoder() {
  const { title, description } = meta.base32decoder
  const [formData, setFormData] = useState({
    encoded: "",
    decoded: "",
  })

  const onChange = e => {
    setFormData(formData => ({
      ...formData,
      [e.target.name]: e.target.value,
    }))
  }

  const decode = e => {
    e && e.preventDefault()
    let decodedStr = ""
    try {
      decodedStr = base32.decode(formData.encoded)
    } catch (err) {
      decodedStr =
        "Failed to decode: The string to be decoded is not correctly encoded."
    }
    setFormData(formData => ({
      ...formData,
      decoded: decodedStr,
    }))
  }

  return (
    <Layout>
      <Seo title={title} description={description} />
      <PageTitle titleText={title} />
      <Panel>
        <PanelTitle titleText="Convert Base32 Encoded Text to Plain Text" />
        <PanelContent>
          <form id="form">
            <label htmlFor="encoded">
              Enter encoded text below and click Decode button :
            </label>
            <textarea
              id="encoded"
              required="required"
              className="form-control"
              name="encoded"
              style={{ height: "150px" }}
              onChange={onChange}
              value={formData.encoded}
            ></textarea>

            <br />
            <span
              tabIndex="0"
              role="button"
              className="btn btn-primary"
              onClick={decode}
              onKeyDown={decode}
            >
              Decode
            </span>

            <br />
            <br />

            <label htmlFor="decoded">
              Converted text will be shown in below text box :
            </label>
            <textarea
              id="decoded"
              required="required"
              className="form-control"
              name="decoded"
              style={{ height: "150px" }}
              onChange={onChange}
              value={formData.decoded}
            ></textarea>
          </form>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText={"About " + title} />
        <PanelContent>
          <p>{description}</p>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText="What is Base32?" />
        <PanelContent>
          <p>
            Base32 is the base-32 numeral system. It uses a set of 32 digits,
            each of which can be represented by 5 bits (2<sup>5</sup>). One way
            to represent Base32 numbers in a human-readable way is by using a
            standard 32-character set, such as the twenty-six upper-case letters
            A–Z and the digits 2–7. However, many other variations are used in
            different contexts.
          </p>
          <p>
            This is an example of a Base32 number represented using the
            previously described 32-character set (IPFS CIDv1 in Base32
            upper-case encoding):
          </p>
          <pre>
            8dqpwtvjc5u7av31ehmpyvkk44g5jvvn41gq4t90dxq6a83fcrg78u3541v6awkt41k6axt0exm6y831cdu7arbcdhwj0wk5c5j20rb2dxuq883mcnhpgvkfdhqpey90c5q68839dnr6rtbdcnq78bh09djpaw10d5u20xbg5r
          </pre>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText="How to use Base32 Decoder Online Tool" />
        <PanelContent>
          <p>Use below steps to decode a base32 encoded string:</p>
          <ol>
            <li>Open Base32 Decoder Online Tool</li>
            <li>Enter Base32 encoded text in first text box</li>
            <li>Click on Decode button</li>
            <li>Check the second text box for decoded plain text output</li>
          </ol>
        </PanelContent>
      </Panel>
    </Layout>
  )
}

export default Base32Decoder
